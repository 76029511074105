// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { capitalizeFLetter, ajaxPerso, fetchStores } from '../../fnc'
import Loading from './Loading';

const refConst = require("../../constants.js").v

const initialState = {
	// itemReadyForStoreSelected: {storeFrom: null, storeTo: null, items: []},
	items: [],
	selectedPolicy: null,
	submited: false,
	_isMounted: false,
	required: ['Category_', 'Brand_']
}

export default function UpdateShopifyTags(props){

	const [stores, setStores] = useState([]);
	const [required, setRequired] = useState(initialState.required);
	const [submited, setSubmited] = useState(initialState.submited);
	const [resReq, setResReq] = useState(null);
	const [items, setItems] = useState(initialState.items.slice(0));
	const [version, setVersion] = useState("0.9.0");
	const [selectedStore, setSelectedStore] = useState(refConst.id_store_eio);
	const [selectedPolicy, setSelectedPolicy] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const inputAdd = useRef()

	useEffect(() => {
		initialState._isMounted = true;
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		if(modalOpen)
			modalTrigger(true);
	}, [items, submited, selectedStore, selectedPolicy, stores, modalOpen, resReq])

	useEffect(() => {
		if(modalOpen){
			setSubmited(false)
			getShopifyitems();
		}
	}, [selectedStore, modalOpen])

	useEffect(() => {
		getShopifyitems();
		setResReq(null)
	}, [selectedPolicy])
	
	/* useEffect(() => {
		// Refetch our product once updated
		if(modalOpen && resReq){
			setTimeout(() => {
				getShopifyitems();
			}, 2000);
		}
	}, [resReq]) */

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				let found = ["_isMounted"].find(str => str === key)
				if(!found)
					console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}
	
	let triggerClick = () => {
		setModalOpen(true)
		setStores(props.stores.filter(o => {
			if(o.id_marketplace == refConst.id_marketplace_shopify)
				return o
		}))

		modalTrigger(false);
	}

	let filtered = () => {
		if(items.length === 0)
			return []
		return items;
	}

	let modalTrigger = (refresh) => {
		props.modal({
			show: refresh? false : true,
			title: "Update Inventory Policy",
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				console.info('Item ready for that store', items);

				let objPreSelected = refConst.shopList.find(obj => {
					if(obj.storeId === props.store)
						return obj
				})
				
				let storeSelectedObj = stores.length > 0 && selectedStore? stores.find(o => {
					if(o.id == selectedStore)
						return o;
				}) : null

				let oppositPolicy = selectedPolicy == "continue"? "deny" : "continue"
				
				return stores.length === 0? <Loading loading={true} centered={true}/> : <div>
					<p className="h5 mb-4">Item compatible for the selected marketplace: {items.length}</p>
					<div className="container bg-light p-3 border mb-3">
						<div className="row">
							<div className="col-sm">
								{
									<Select
										styles={refConst.styleList}
										onChange={(selectedOption) => {
											console.info('selectedOption.value ', selectedOption.value );						
											setSelectedStore(selectedOption && selectedOption.value != '-'? selectedOption.value : initialState.selectedStore)
										}}
										options={(() => {
											return stores.map(o => {
												return {
													value: o.id,
													label: o.name
												}
											})
										})()}
										value={selectedStore && stores.length > 0? {
											value: selectedStore,
											label: storeSelectedObj.name,
										} : null}
										isSearchable={true}
										isMulti={false}
										placeholder={"Select the store"}
									/>
								}
							</div>
						</div>
					</div>
					<div className="container bg-light p-3 border mb-3">
						<div className="row">
							<div className="col-sm d-flex align-items-center">
								<p className="m-0">Continue selling when out of stock</p>
							</div>
							<div className="col-sm">
								{
									<Select
										styles={refConst.styleList}
										onChange={(selectedOption) => {
											console.info('selectedOption.value ', selectedOption.value );						
											setSelectedPolicy(selectedOption && selectedOption.value != '-'? selectedOption.value : initialState.selectedPolicy)
										}}
										options={(() => {
											return ['deny', 'continue'].map(t => {
												return {
													value: t,
													label: t
												}
											})
										})()}
										value={selectedPolicy? {
											value: selectedPolicy,
											label: selectedPolicy
										} : null}
										isSearchable={true}
										isMulti={false}
										placeholder={"Select the inventory policy"}
									/>
								}
							</div>
						</div>
					</div>
					<div>
						<table className="table">
							<thead>
								<tr>
									<th scope="col" className="bg-info text-white">Id</th>
									<th scope="col" className="bg-info text-white">Brand</th>
									<th scope="col" className="bg-info text-white">SKU</th>
									<th scope="col" className="bg-info text-white">Inventory Policy</th>
									{resReq && Object.keys(resReq).length > 0? <th scope="col" className="bg-primary text-white">Status</th> : null}
								</tr>
							</thead>
							{
								items.length > 0?
									<tbody>
										{
											filtered().map(o => {
												if(!("newToRemove" in o))	o.newToRemove = []
												return	<tr key={o.id + "lineTagsSystem"}>
													<th scope="row">{o.id}</th>
													<td>{o.vendor}</td>
													<td>{o.sku}</td>
													<td>{resReq && Object.keys(resReq).length > 0? (resReq[o.id_variant_shopify.toString()].status == 200? selectedPolicy : oppositPolicy) : (o.selling_when_out_of_stock? "continue" : "deny")}</td>
													{
														resReq && Object.keys(resReq).length > 0?
															<td>
																{
																	Object.keys(resReq).indexOf(o.id_variant_shopify.toString()) !== -1? <div className="d-flex">
																		<button type="button" className="btn btn-primary btn-sm bg-secondary text-white mb-1"><a href={"https://" + storeSelectedObj.account_name + "/admin/products/" + resReq[o.id_variant_shopify.toString()].id_product_shopify} className="text-white" target="_blank" rel="noopener noreferrer">Admin</a></button>
																		<button type="button" className="btn btn-primary btn-sm bg-secondary text-white mb-1 ml-2"><a href={"https://" + storeSelectedObj.account_name + "/products/" + resReq[o.id_variant_shopify.toString()].handle} className="text-white" target="_blank" rel="noopener noreferrer">Store</a></button>
																	</div> : (Object.keys(resReq).length === 0? '-' : 'Nothing changed')
																}
															</td> : null
													}
												</tr>
											})
										} : <tr><td colSpan="4">No item found</td></tr>
									</tbody>
									: null
							}
						</table>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				setModalOpen(false)
				close()
			}
			, ok: !submited? {
				title: "Validate",
				fnc: (popup, close) => {
					// props.loading(true)
					setSubmited(true)
					props.loading(true)

					reqSubmit((r) => {

						setSubmited(false)
						props.loading(false)

						let success = [],
							error = [],
							itemsCreatedList = {};

						console.info('Fetch reqSubmit', r);

						if(r.error){
							props.info({error: "Something bad happend in 'reqSubmit'."})
						}else{
							if(!r.res){
								props.info({error: "Nothing required to update."})
							}else if(r.res.data.updated.length > 0){

								r.res.data.updated.forEach(iu => {

									let found = items.find(o => {
										if(o.id_variant_shopify == iu.idReceived)
											return o;
									})

									if(found)
										itemsCreatedList[iu.idReceived.toString()] = Object.assign(found, {status: iu.code})
								})
	
								setResReq(itemsCreatedList)
	
								success.push("Items inserted ("+Object.keys(itemsCreatedList).length+"): ")
								success.push(Object.keys(itemsCreatedList).join(', '))
							}
						}

						if(r.error)		error.push(r.error)

						props.info({success: success, error: error})
					})
					// resetPage()
					// popup.modal('hide')
				}
			} : false, return: {
				title: "Exit",
				fnc: (popup, close) => {
					setModalOpen(false)
					resetPage()
					close()
				}
			}
		});
	}

	let reqSubmit = (callback) => {
		if(!selectedStore || !items)
			throw("Please indicate a storeId / itemList.");
		else{
			ajaxPerso({
				"api": "shopify",
				"trigger": "bulkUpdateInventoryPolicy",
				"shop": selectedStore,
				"policy": selectedPolicy,
				"skuList": items.map(o => {
					return o.sku
				}).join(',')
			}, callback);
		}
	}

	let fetchShopifyItems = (callback) => {
		if(!selectedStore || props.itemsSelected.length === 0)
			console.warn("Please indicate a list of product ID.");
		else
			ajaxPerso( {
				"api": "shopify",
				"trigger": "getItems",
				"shop": selectedStore,
				"idProductListJson": JSON.stringify(props.itemsSelected),
			}, callback);
	}

	let getShopifyitems = () => {
		props.loading(true)
		fetchShopifyItems((r) => {
			if(!initialState._isMounted)	return false
			if(r.success){
				console.info('Fetched Shopify Items', r);
				props.loading(false)
				setItems(r.data || initialState.items.slice(0))
			}else{
				props.info({"error": r.error})
			}
		})
	}

	return (
		<div className="list-group-item list-group-item-action p-2 text-center" onClick={() => triggerClick()}>
			{
				props.title
			}
		</div>
	);
}